/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import api, { url } from "../../api";
import UploadForm from "../empreendimentos/novoPredio/components/uploadForm";
import { Input, Form, Button, message, Select, Upload } from "antd";
import { navigate } from "gatsby";
import { AuthContext } from "../../context/GlobalContextProvider";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import PrediosSelect from "./components/prediosCarrossel";

export default function Sobre() {
  const [page, setPage] = useState();
  const context = useContext(AuthContext);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [depoimentos, setDepoimentos] = useState([]);
  console.log("asd-d", depoimentos);

  const uploadButton = (
    <div>
      {<UploadOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  useEffect(() => {
    getPage();
  }, []);

  const getPage = async () => {
    setLoadingData(true);
    const { data } = await api.get("/sobre");
    setPage(data);
    setDepoimentos(
      data?.depoimentos?.map((g) => ({
        uid: g.id,
        name: g.name + g.ext,
        status: "done",
        url: g.url && `${url}${g.url}`,
      }))
    );
    setLoadingData(false);
  };

  const handleSubmit = async (values) => {
    console.log("asd-v", values);
    setLoadingSave(true);
    var data = new FormData();
    values.imagem &&
      data.append(
        `files.imagem`,
        values.imagem[0]?.originFileObj,
        values.imagem[0]?.name
      );
    values.isoLogo &&
      data.append(
        `files.isoLogo`,
        values.isoLogo[0]?.originFileObj,
        values.isoLogo[0]?.name
      );
    values.pbqpLogo &&
      data.append(
        `files.pbqpLogo`,
        values.pbqpLogo[0]?.originFileObj,
        values.pbqpLogo[0]?.name
      );
    let depoimentosArray = [];
    depoimentos?.map((g) =>
      typeof g?.originFileObj === typeof {}
        ? data.append("files.depoimentos", g?.originFileObj, g.name)
        : depoimentosArray.push(g.uid)
    );
    data.append(
      "data",
      JSON.stringify({
        quemSomos: values.quemSomos,
        missao: values.missao,
        bivverDream: values.bivverDream,
        bivverClub: values.bivverClub,
        bivverExclusivity: values.bivverExclusivity,
        valores: values.valores,
        visao: values.visao,
        compromissoSocial: values.compromissoSocial,
        predios: values.carousel,
        depoimentos: depoimentosArray,
      })
    );
    localStorage.removeItem("sobre");
    await api({
      method: "PUT",
      url: "/sobre",
      headers: {
        Authorization: `Bearer ${context?.token}`,
      },
      data,
    }).then((value) => {
      if (value) {
        message.success("Página salva com sucesso");
        navigate(`/institucional`);
      }
    });
    setLoadingSave(false);
  };

  return (
    <Layout image="" loading={loadingData}>
      <SEO title="Institucional" />
      <Form layout="vertical" onFinish={handleSubmit}>
        <PrediosSelect
          initialValue={page?.predios}
          carrossel
          {...{ Form, name: "carousel" }}
        />
        <h2 id="quemSomos" className="mt-5">
          Institucional?
        </h2>
        <Form.Item name="quemSomos" initialValue={page?.quemSomos}>
          <Input.TextArea className="mb-10" autoSize />
        </Form.Item>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-8 my-12">
          <UploadForm
            imgUrl={`${url}${page?.imagem?.url}`}
            field="imagem"
            Form={Form}
          />
        </div>
        <h2 id="bivverDream" className="mb-3 mt-10">
          Bivver Dream
        </h2>
        <Form.Item name="bivverDream" initialValue={page?.bivverDream}>
          <Input.TextArea autoSize />
        </Form.Item>
        <h2 id="bivverClub" className="mb-3 mt-10">
          Bivver Club
        </h2>
        <Form.Item name="bivverClub" initialValue={page?.bivverClub}>
          <Input.TextArea autoSize />
        </Form.Item>
        <h2 id="bivverExclusivity" className="mb-3 mt-10">
          Bivver Exclusivity
        </h2>
        <Form.Item
          name="bivverExclusivity"
          initialValue={page?.bivverExclusivity}
        >
          <Input.TextArea autoSize />
        </Form.Item>
        <h2 id="missao" className="mb-3 mt-10">
          Missão
        </h2>
        <Form.Item name="missao" initialValue={page?.missao}>
          <Input.TextArea autoSize />
        </Form.Item>
        <h2 id="visao" className="mb-3 mt-10">
          Visão
        </h2>
        <Form.Item name="visao" initialValue={page?.visao}>
          <Input.TextArea autoSize />
        </Form.Item>
        <h2 id="valores" className="mb-3 mt-10">
          Valores
        </h2>
        <Form.Item name="valores" initialValue={page?.valores}>
          <Input.TextArea autoSize />
        </Form.Item>
        <h2 id="compromissoSocial" className="mb-3 mt-10">
          Política de qualidade
        </h2>
        <Form.Item
          name="compromissoSocial"
          initialValue={page?.compromissoSocial}
        >
          <Input.TextArea autoSize />
        </Form.Item>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-8 my-12">
          <UploadForm
            imgUrl={`${url}${page?.isoLogo?.url}`}
            field="isoLogo"
            Form={Form}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-8 my-12">
          <UploadForm
            imgUrl={`${url}${page?.pbqpLogo?.url}`}
            field="pbqpLogo"
            Form={Form}
          />
        </div>
        <h2 className="text-center mt-16">Depoimentos</h2>
        <Upload
          name="depoimentos"
          fileList={depoimentos}
          onChange={(e) => setDepoimentos(e.fileList)}
        >
          {uploadButton}
        </Upload>
        <Form.Item>
          <Button
            style={{ backgroundColor: "orange", color: "white" }}
            htmlType="submit"
          >
            {loadingSave && <LoadingOutlined />} Salvar
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
}
